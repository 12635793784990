import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 0.1em 0.25em;
  width: 13em;
  height: 3.2em;
  cursor: pointer;
  border: 0.08em double #fff;
  border-radius: 0.3em;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  font-family: EBGaramond;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;
export const StyledButtonConnect = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  height: 100px;
  border-radius: 100px;
  border: none;
  background: rgba(51, 51, 51, 0);
  color: #e5e5e5;
  font-weight: 600;
  font-family: EBGaramond;
  /* box-shadow: inset 20px 20px 39px #080a10, inset -20px -20px 39px #1b263e; */
  font-size: 26px;
  :after {
    content: "";
    position: absolute;
    z-index: -1;
    left: -10px;
    top: -10px;
    border-radius: 50%;
    border: solid 4px #fff;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    animation: rotcirclegr 1s linear infinite;
    background: linear-gradient(
      35deg,
      rgb(47, 190, 238),
      rgb(218, 57, 143),
      rgb(139, 255, 23)
    );
    filter: blur(10px);
  }
  :hover {
    font-size: 1rem;
  }
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: lightblue;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgEth = styled.img`
  /* border: 4px dashed var(--secondary); */
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 3px;
  width: 100px;
  @media (min-width: 900px) {
    width: 100px;
  }
  @media (min-width: 1000px) {
    width: 100px;
  }
  transition: width 0.5s;
  :hover {
    box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  }
`;

export const StyledImgOpenSea = styled.img`
  /* border: 4px dashed var(--secondary); */
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  padding: 3px;
  width: 40px;
  @media (min-width: 900px) {
    width: 40px;
  }
  @media (min-width: 1000px) {
    width: 40px;
  }
  transition: width 0.5s;
  :hover {
    box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  }
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgba(51, 51, 51, 0.7);
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click "Mint" to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [minted, setminted] = useState(0);
  const [mintedWl, setmintedWl] = useState(0);
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);
  const [whitelist2Valid, setwhitelist2Valid] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COSTWL: 0,
    WEI_COSTPB: 0,
    DISPLAY_COST: 0,
    MAX_MINT: 1,
    MAX_MINTWL: 1,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const checkMinted = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(true)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  // setPublicSaleEnable
  const setPublicSaleEnable = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(!data.isPsEnabled)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にセール開始されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  // 41. setWhitelistSaleEnable (0xc3faf724)
  const setWhitelistSaleEnable = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(!data.isWlEnabled)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にセール開始されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  // 41. setWhitelistSaleEnable (0xc3faf724)
  const setWlMaxMints = (amount) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWlMaxMints(amount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に上限解放されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  // 41. setWhitelistSaleEnable (0xc3faf724)
  const setmaxMintsPerPS = (amount) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setmaxMintsPerPS(amount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に上限解放されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTswl = (_amount) => {
    let cost = CONFIG.WEI_COSTWL;
    let gasLimit = CONFIG.GAS_LIMIT;
    _amount = Number(_amount);
    let totalCostWei = String(cost * _amount);
    // let totalGasLimit = String(gasLimit * _amount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    console.log(_amount);
    console.log(whitelistProof);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(_amount, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedwl();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const getWl = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let whitelist = require("./data/whitelist2.json");
      if (false) {
        let adresses = whitelist.map((addr) => addr.toLowerCase());
        let index = adresses.indexOf(address);
        let validNun = whitelist[index];
        if (validNun !== undefined) {
          setwhitelistValid(true);
        } else {
          let whitelist = require("./data/whitelist2.json");
          let adresses = whitelist.map((addr) => addr.toLowerCase());
          let index = adresses.indexOf(address);
          let validNun = whitelist[index];
          if (validNun !== undefined) {
            setwhitelist2Valid(true);
          }
        }
        console.log("wl:::" + index + validNun);
      } else {
        let { MerkleTree } = require("merkletreejs");
        let keccak256 = require("keccak256");
        let hashedAddresses = whitelist.map((addr) => keccak256(addr));
        let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
          sortPairs: true,
        });
        let hashedAddress = keccak256(address);
        let proof = merkleTree.getHexProof(hashedAddress);
        let root = merkleTree.getHexRoot();
        // console.log('WL:hasheBefore/'+address);
        // console.log('WL:hashed/'+hashedAddress);
        blockchain.smartContract.methods
          .isWhitelisted(blockchain.account, proof)
          .call()
          .then((receipt) => {
            setwhitelistProof(proof);
            setwhitelistValid(receipt);
            // console.log('whitelistValid/'+whitelistValid);
            dispatch(fetchData(blockchain.account));
            if (!whitelistValid) {
              let whitelist = require("./data/whitelist2.json");
              let adresses = whitelist.map((addr) => addr.toLowerCase());
              let index = adresses.indexOf(address);
              let validNun = whitelist[index];
              if (validNun !== undefined) {
                setwhitelist2Valid(true);
              }
            }
          });

        // setwhitelistProof(proof);
        // setwhitelistValid(valid);
        console.log("WL:proof/" + proof);
        console.log("WL:root/" + root);
        // console.log(whitelistValid);
      }
    }
  };

  const checkMintedwl = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWl(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > datasub.maxMintsPerPS - minted) {
      newMintAmount = datasub.maxMintsPerPS - minted;
    }
    setMintAmount(newMintAmount);
  };
  const getReload = () => {
    location.reload();
  };

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };
  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    }
    getData();
    getDataSub();
    getWl();
    checkMinted();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    getData();
    getDataSub();
    checkMinted();
    checkMintedwl();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#232330" }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
        image={
          isMobile
            ? "/config/images/zitogatitoSMP.png"
            : "/config/images/zitogatito.png"
        }
        // image={isMobile ? null : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/header.png"} />
        {/* <StyledLogo alt={"logo"} src={null} /> */}
        <s.SpacerSmall />
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 24, background: "" }}
          // test
        >
          {/* {isMobile ? (<></>) : (<>

            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              padding: 24,
              borderRadius: 24,
              // border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            }}
          >
            {blockchain.account === "" ||
            blockchain.smartContract === null ||
            blockchain.account === undefined ? (
              <></>
            ) : (
              <>
                {data.deployer == blockchain.account ? (
                  <>
                    <s.SpacerSmall />
                    {!data.isWlEnabled || true ? (
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          setWhitelistSaleEnable();
                          getInit();
                        }}
                      >
                        {claimingNft ? (
                          "設定中"
                        ) : (
                          <>
                            {data.isWlEnabled ? "ALセール終了" : "ALセール開始"}
                          </>
                        )}
                      </StyledButton>
                    ) : (
                      <></>
                    )}
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        setWlMaxMints(2);
                        getInit();
                      }}
                    >
                      {claimingNft ? "設定中" : "上限解放"}
                    </StyledButton>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        setReveal();
                        getInit();
                      }}
                    >
                      {claimingNft ? "設定中" : "リビール"}
                    </StyledButton>
                    <s.SpacerSmall />
                    {!data.isPsEnabled || true ? (
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          setPublicSaleEnable();
                          getData();
                        }}
                      >
                        {claimingNft ? (
                          "開始中"
                        ) : (
                          <>
                            {data.isWlEnabled ? "PBセール終了" : "PBセール開始"}
                          </>
                        )}
                      </StyledButton>
                    ) : (
                      <></>
                    )}
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        setmaxMintsPerPS(2);
                        getInit();
                      }}
                    >
                      {claimingNft ? "設定中" : "PB上限解放"}
                    </StyledButton>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        withdraw();
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "出金(任意)"}
                    </StyledButton>
                    <s.SpacerSmall />
                  </>
                ) : (
                  <></>
                )}{" "}
              </>
            )}

            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              -Zitto & Gattito NFT-
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              AL Sale：1/22(SUN) 19:00〜
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              AL 2nd.Sale：1/22(SUN) 20:10〜
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              Public Sale：1/23(MON) 19:00〜
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {/* {truncate(CONFIG.CONTRACT_ADDRESS, 15)} */}
                <StyledImgEth
                  alt={"etherscan"}
                  src={"/config/images/logo-etherscan.svg"}
                />
              </StyledLink>
            </s.TextDescription>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  完売！！ありがとうございます！！今後ともよろしくお願いします。
                </s.TextTitle>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {"AL Sale:"}
                  {CONFIG.DISPLAY_COST}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>
                {/* <s.TextDescription
                  style={{
                    color: "var(--accent-text)",
                  }}
                >
                  {"(Max "}
                  {CONFIG.MAX_MINTWL}
                  {"mint)"}
                </s.TextDescription> */}
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {"Public:"}
                  {CONFIG.DISPLAY_COSTPB}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>
                {/* <s.TextDescription
                  style={{
                    color: "var(--accent-text)",
                  }}
                >
                  {"(Max "}
                  {CONFIG.MAX_MINT}
                  {"mint)"}
                </s.TextDescription> */}
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.（別途ガス代が必要です）
                </s.TextDescription>

                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ||
                blockchain.account === undefined ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButtonConnect
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getInit();
                      }}
                    >
                      CONNECT
                    </StyledButtonConnect>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"接続アドレス："+blockchain.account}
                    </s.TextDescription> */}
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    ></s.TextDescription>
                    {data.isPsEnabled || data.isWlEnabled ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 25,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"Now On Sale!"}
                        </s.TextTitle>
                      </>
                    ) : (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"セール準備中..."}
                        </s.TextTitle>
                      </>
                    )}

                    {/* アローリスト所有者の方ここから */}
                    {whitelistValid ? (
                      <>
                        {data.isPsEnabled ? (
                          <></>
                        ) : (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"あなたはアローリスト所有者です("}
                              {mintedWl}
                              {"mint済)"}
                            </s.TextDescription>
                          </>
                        )}

                        {data.isWlEnabled ? (
                          <>
                            <s.SpacerMedium />
                            {mintedWl >= datasub.maxMintsPerWL ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"AL MINTありがとうございました"}
                                  </s.TextDescription>
                                </s.Container>
                              </>
                            ) : (
                              <>
                                {datasub.maxMintsPerWL - mintedWl == 1 ? (
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTswl(1);
                                        getInit();
                                      }}
                                    >
                                      {claimingNft ? "BUSY" : "AL 1 MINT"}
                                    </StyledButton>
                                  </s.Container>
                                ) : (
                                  <></>
                                )}
                                {datasub.maxMintsPerWL - mintedWl == 2 ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTswl(1);
                                          getInit();
                                        }}
                                      >
                                        {claimingNft ? "BUSY" : "AL 1 MINT"}
                                      </StyledButton>
                                    </s.Container>
                                    <s.SpacerXSmall />
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButton
                                        disabled={claimingNft ? 1 : 0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTswl(2);
                                          getInit();
                                        }}
                                      >
                                        {claimingNft ? "BUSY" : "AL 2 MINT"}
                                      </StyledButton>
                                    </s.Container>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {/* {"アローリストセールは開始していません"} */}
                            </s.TextDescription>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {data.isPsEnabled ? (
                          <></>
                        ) : (
                          <>
                            {whitelist2Valid ? (
                              <>
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {"あなたはアローリスト[20:10〜]所有者です"}
                                </s.TextDescription>
                              </>
                            ) : (
                              <>
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {
                                    "このアドレスはアローリストに含まれていません。"
                                  }
                                </s.TextDescription>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {/* ここからパブリックセール */}
                    <s.SpacerMedium />
                    {data.isPsEnabled ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"パブリックセール("}
                          {minted}
                          {"mint済)"}
                        </s.TextDescription>

                        {datasub.maxMintsPerPS == minted ? (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"MINTいただきありがとうございました"}
                            </s.TextDescription>
                          </>
                        ) : (
                          <>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                -
                              </StyledRoundButton>
                              <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                  padding: 20,
                                }}
                              >
                                {mintAmount}
                              </s.TextDescription>
                              <s.SpacerMedium />
                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                +
                              </StyledRoundButton>
                            </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getInit();
                                }}
                              >
                                {claimingNft ? "BUSY" : mintAmount + " MINT"}
                              </StyledButton>
                            </s.Container>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"パブリックセールは開始していません"}
                        </s.TextDescription>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />

            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              NFT Market Place
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {/* {CONFIG.MARKETPLACE} */}
              <StyledImgOpenSea
                alt={"opensea"}
                src={"/config/images/opensea.svg"}
              />
            </StyledLink>
            <s.SpacerSmall />
            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <StyledButtonReload
                onClick={(e) => {
                  e.preventDefault();
                  getReload();
                }}
              >
                {claimingNft ? "Busy" : "RELOAD"}
              </StyledButtonReload>
            </s.Container>
          </s.Container>
          <s.SpacerLarge />
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/circle.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
          {/* {isMobile ? (<></>) : (<>
            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImg alt={"example"} src={"/config/images/logo.png"} /> */}
            <CircleTextLogo />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary)",
            }}
          >
            正しいネットワークに接続されていることを確認してください (
            {CONFIG.NETWORK.NAME}{" "}
            Mainnet)と正しいアドレスに接続されていることを確認してください。ご注意ください。
            一度購入すると、この操作を取り消すことはできません。
          </s.TextDescription>
          {/* <StyledLinkMini
            target={"_blank"}
            href={"https://twitter.com/freakyyyy_frick"}
          >
            Code: @FRICKLIK
          </StyledLinkMini> */}
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
